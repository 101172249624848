<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Manrope:wght@400;500;600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#000;
}

#app {
  display: flex;
  min-height: 100vh;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
}

.container {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.mobile-container {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.instructions {
  font-size: 2vw;
  color: #fff;
  padding: 30px;
}

.all-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  contain: strict;
  width: 500px;
  height: 750px;
  background-color: #faf9f6;
  border-radius: 25px;
  box-shadow: 4px 5px 3px 3px rgb(0 0 0 / 30%);
}

.top-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  background-color: #000;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 59.5%;
  height: 60px;
  padding-left: 32px;
}

.top-container-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: #000;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 40%;
  height: 60px;
  padding-right: 32px;
}

.bottom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  background-color: #000;
  border-radius: 15px;
  width: 100%;
  height: 60px;
  padding-left: 32px;
}

.heading-label {
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 20px;
  color: #fff;
}

.logo-container{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  align-items: flex-start;
}
.logo{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mobile-office-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 200px;
}

.mobile-logo-container{
  height: 100px;
  width: auto;
  object-fit: contain;
}

.mobile-office-name-container{
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #fff;
}

.practice-heading{
  font-size: 1.5vw;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
}

.page-space {
  width: 100vw;
  height: 5px;
}

.tab {
  width: 100%;
  height: 40px;
}

.selection {
  padding-top: 20px;
  padding-left: 15px;
}

.selection-text {
  font-size: 1.1vw;
  color: #636161;
  padding-bottom: 7px;
}

.mobile-selection-text {
  font-size: 5vw;
  color: #636161;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
}

.logout-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 100%;
}

.logout-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.logout-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.mobile-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.mobile-button {
  cursor: pointer;
  width: 85%;
  height: 40px;
  border-radius: 15px;
  background-color: #004EAA;
  color: #fff;
  font-size: 16px;
}
button {
  cursor: pointer;
  width: 360px;
  height: 35px;
  border-radius: 15px;
  background-color: #004EAA;
  color: #fff;
  font-size: 14px;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
