<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="container" v-show="desktop">
    <HeaderWidget />
    <div class="page-space"></div>
    <div class="welcome-container">
      <div class="instructions">
        {{ introBlurb }}
      </div>
    <div class="login-all-container">
      <div class="intro-container">
      <!-- pulls information from composition API.
            In this instance replaces PracticeName with value imported. -->
    </div>
    <div class="login-container">
      <!-- submit.prevent stops default action of a form
            and replaces it with the sendForm method which
            is found in the script section of the code. -->
      <form @submit.prevent="sendForm" v-if="!submitted">
        <span>
          <!-- Text Box for user to input phone number and saved in
                composition API under the variable PhoneNumber. -->
          <div class="selection-text">
            Phone Number
          </div>
          <label for="Phone">
            <input type="text"
              placeholder="Phone"
              id="Phone" required
              v-model="PhoneNumber"
              @input="acceptNumber"
            >
          </label>
        </span>
        <span>
          <!-- Text Box for user to input phone number and saved in
                composition API under the variable PIN. -->
          <div class="selection-text">
            Pin Code
          </div>
          <label for="PIN">
            <input type="password"
              placeholder="PIN"
              id="PIN" required
              v-model="PIN"
            >
          </label>
        </span>
        <span>
          <!-- Custom button with word submit. -->
          <button>Sign In</button>
        </span>

      </form>
    </div>
    </div>
    </div>
    <div class="page-space"></div>
    <div class="bottom-container">here</div>
  </div>
  <div class="mobile-container" v-show="mobile">
    <div class="welcome-container">
      <div class="mobile-office-container">
        <div class="mobile-logo-container">
          <img v-if="logoData" :src="logoData" alt="Logo"/>
        </div>
        <div class="mobile-office-name-container" v-if="nameData">
          {{ nameData }}
        </div>
      </div>
      <div class="mobile-instructions">
        {{ introBlurb }}
      </div>
      <div class="login-mobile-container">
        <form @submit.prevent="sendForm" v-if="!submitted">
          <!-- Text Box for user to input phone number and saved in
                composition API under the variable PhoneNumber. -->
          <span>
          <div class="mobile-selection-text">
            Phone Number
          </div>
          <label class="mobile-input" for="Phone">
            <input
            type="text"
              placeholder="Phone"
              id="Phone" required
              v-model="PhoneNumber"
              @input="acceptNumber"
            >
          </label>
        </span>
        <span>
          <!-- Text Box for user to input phone number and saved in
                composition API under the variable PIN. -->
          <div class="mobile-selection-text">
            Pin Code
          </div>
          <label class="mobile-input" for="PIN">
            <input type="password"
              placeholder="PIN"
              id="PIN" required
              v-model="PIN"
            >
          </label>
        </span>
        <span>
          <div class="page-space"></div>
          <!-- Custom button with word submit. -->
          <div class="mobile-button-container">
            <button class="mobile-button">
              Sign In
            </button>
          </div>
        </span>

      </form>
      </div>
    </div>
  </div>
</template>

<script>
// Imports the appointment query class from js file.
import AppointmentQuery from '../js/query';
// Imports the vuex store from the store folder so that we can save needed variables globally.
import store from '../store/index';
import HeaderWidget from './widgets/HeaderWidget.vue';

export default {
  components: { HeaderWidget },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      tablet: null,
      desktop: null,
      // Will be imported value from URL once web address is configured.
      ClientID: '',
      // ClientID: 'hoQN3shDOSco7AGYMIH5TIGdqEg=',
      PhoneNumber: '',
      PIN: '',
      PracticeName: '',
      PracticeLogo: '',
      introBlurb: 'Sign In',
      submitted: null,
    };
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  methods: {
    /* async is used to run this method asynchronously so that the method can wait
    for a reply from the fetch (await used before AppointmentQuery) the promise will
    be completed before the program steps past the fetch. */
    async sendForm() {
      // console.log('ClientID: ', this.ClientID);
      const ph = this.PhoneNumber.replace('(', '').replace(')', '').replace('-', '');
      const res = await AppointmentQuery.getValidation(this.ClientID, ph, this.PIN);
      // checking to make sure that the login information is valid on the back-end.
      if (res.IsValid) {
        // Message tracking on the console for troubleshooting purposes.
        console.log('You have been authenticated');
        // Saves the session ID into the store so that is could be used globally.
        store.commit('addSession', res.Session);
        // Pushes the webpage to the authenticated section of the web app.
        this.$router.push('/authorized');
      } else {
        // Message tracking should authentication fail.
        console.log('The credentials failed authorization');
        // Resets values of the credentials on the web page.
        this.PhoneNumber = '';
        this.PIN = '';
        // program pop up alert to warn credentials failed
        this.$router.push('/home');
      }
    },
    async welcomeScreen() {
      const results = await AppointmentQuery.welcomeScreen(this.ClientID);
      // const results = await AppointmentQuery.welcomeScreen();
      this.PracticeName = results.Offices[0].Name;
      this.PracticeLogo = results.Offices[0].LogoPath;
      store.commit('addPracticeName', this.PracticeName);
      store.commit('addPracticeLogo', this.PracticeLogo);
    },
    acceptNumber() {
      const x = this.PhoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.PhoneNumber = !x[2] ? x[1] : `(${x[1]})${x[2]}${x[3] ? `-${x[3]}` : ''}`;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth >= 600) {
        this.desktop = true;
        this.tablet = false;
        this.mobile = false;
      } else if (this.windowWidth < 600) {
        this.tablet = false;
        this.desktop = false;
        this.mobile = true;
      }
    },
  },
  beforeMount() {
    if (!this.$route.query.code) {
      console.log('No Client Code Here!');
      this.ClientID = store.state.conClientID;
    } else {
      this.ClientID = this.$route.query.code;
    }
  },
  async mounted() {
    await this.welcomeScreen();
    store.commit('addClientID', this.ClientID);
  },
  computed: {
    loadingStatus() {
      return store.state.loadingStatus;
    },
    logoData() {
      return this.$store.state.conPracticeLogo;
    },
    nameData() {
      return this.$store.state.conPracticeName;
    },
    sessionStarted() {
      return this.$store.state.sessionID;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99vw;
  height: 100%;
  padding-top: 10px;
  padding: 10px;
  border-radius: 15px;
  background: url('../assets/Backgrounds/Blue-Background-1.png');
  background-size: cover;
}

.login-all-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  contain: strict;
  width: 500px;
  height: 310px;
  background-color: #faf9f6;
  border-radius: 25px;
}

.login-mobile-container {
  width: 90%;
  height: 310px;
  border-radius: 25px;
  background-color: #faf9f6;
  overflow: auto;
}

.login-mobile-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.instructions {
  font-size: 2vw;
  color: #fff;
  padding: 30px;
}

.mobile-instructions {
  font-size: 6vw;
  color: #fff;
  padding: 15px;
}

.login-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 600px;
}

.new-client {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  height: 20px;
  font-size: 12px;
  padding-top: 6px;
  padding-left: 8px;
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

.mobile-input {
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 90%;
  height: 50px;
  padding: 10px;
}

.mobile-span {
  padding: 5px;
}

span {
  display: block;
  box-sizing: border-box;
  padding: 5px;
}

input {
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid #fff;
  box-shadow: inset 0px 1px 1px rgba(0,0,0, 0.33);
}

</style>
